const Skills = function () {
    const skills = {
      Printmaking: [
        "linocut",
        "screen",
        "monotype",
        "poly",
        "etching",
        "carbon",
        "woodcut",
      ],
      Sculpture: [
        "ceramics",
        "wire",
        "wood",
        "carving",
        "casting",
        "modeling",
        "3D modeling",
        "blender",
        "modrock",
      ],
      Painting: [
        "oil",
        "watercolour",
        "acrylic",
        "inks",
        "mural",
        "spray",
        "portrait",
        "charcoal",
        "gouache",
        "oil/pastels",
      ],
      Digital: [
        "adobe photoshop",
        "adobe illustrator",
        "vector",
        "gimp",
        "krita",
        "affinity",
        "video editing",
      ],
      People: [
        "Community leadership",
        "Communication",
        "Networking",
        "Organisation",
        "time management",
        "Event planning",
        "Managing volunteers",
        "Facilitator ",
        "Pastoral Care",
      ],
      Teaching: [
        "KS3 Computer Science",
        "KS3 Textiles",
        "KS3 Aspire",
        "KS3 Art and Design",
        "GCSE iMedia",
        "GCSE Art and Design",
        "A Level Art and Design",
        "PHSE",
        "Forest School",
      ],
    };
  
    return (
      <div className="skills-grid">
        {Object.keys(skills).map((skill) => {
          return (
            <div style={{ display: "inline-block" }}>
              <h3 style={{ textAlign: "center", margin: "16px" }}>{getFullTitle(skill)}</h3>
              {Object.entries(skills[skill]).map((s) => {
                return (
                  <h4 style={{ textTransform: "capitalize", textAlign: "center", margin: "0 8px 0 4px", fontWeight: "normal" }}>
                    {s[1]}
                  </h4>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };
  
  const getFullTitle = function(title){
  
      if(title === "People"){
          return "People & Community"
      }else if(title === "Painting"){
          return "Painting & Illustration"
      }else{
          return title;
      }
  }
  
  export default Skills;
  