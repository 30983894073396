import { makeAutoObservable, runInAction } from "mobx";

class Store {
    fullGallery = {
        digital: [],
        mixed: [],
        skills: [],
        boards: [],
        comissions: [],
    };
    selectedImg = {
        src: "",
        title: "",
        section: "",
    }
 
    loaded = false;

    constructor() {
        makeAutoObservable(this);
        this.loadPhotos();
    }

    async loadPhotos(){
        const mixed = await this.getFromTag("72177720297470985");
        const digital = await this.getFromTag("72177720297469705");
        const boards = await this.getFromTag("72177720297470810");
        const comissions = await this.getFromTag("72177720297473471");
        const skills = [];
        
        const loadedGallery = {
            digital,
            mixed,
            skills,
            boards,
            comissions,
            
        }

        runInAction(() => {
            this.fullGallery = loadedGallery;
            this.loaded = true;
        })   
    }

    async getFromTag(album_id){
        const pageData = await fetch(`https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=5afd877f6081783cc90a6121278842fd&photoset_id=${album_id}&user_id=195204175%40N06&format=json&nojsoncallback=1`).catch(
            (e) => {
                console.error("no data" + e);
            }
        );

        const json = await pageData.json();
        return json.photoset.photo;
    }

    updateSelectedImg(imgSrc){
        this.selectedImg = imgSrc;
    }
}

export default Store;