import './App.css';
import React from 'react';
import Gallery from './Components/Gallery';
import PopUp from './Components/PopUp';
import Header from './Components/Header';
import {ReactComponent as Chevrons} from "./icons/chevrons-up.svg";

function App() {

  return (
    <div className="App">
      {/* <button onClick={() => {
        const currentTheme = document.documentElement.getAttribute('data-theme');
        document.documentElement.setAttribute('data-theme', currentTheme === 'dark' ? 'light' : 'dark');
      }}>lol</button> */}
      <Header text="Thea Field"/>
      <Gallery />
      <div className='upScroll'>
        <Chevrons width={32} height={32} onClick={() => {
          const element = document.getElementById("top");
          element.scrollIntoView();
        }}/>
      </div>
      <PopUp/>
      <footer style={{textAlign: "center", padding: "32px"}}>
      <a href="mailto:theahelenafield@gmail.com?body=Hi Thea">Contact Me</a>
      <br/>
      Thea Field - Copyright © 2022
      </footer>
    </div>
  );
}

export default App;
