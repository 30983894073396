import { observer } from "mobx-react";
import { useStores } from "../hooks/useStores";

const PopUp = observer(() => {
  const { dataStore } = useStores();
  return dataStore.selectedImg.src && (
    <div
      className="overlay"
      onClick={() => {
        dataStore.updateSelectedImg({
            src: "",
            title: "",
            section: "",
        });
      }}
    >
      <div style={{textAlign: "center", color:"white"}}>
        <h3>
        {dataStore.selectedImg.title}
        <br/>
        {dataStore.selectedImg.section.toUpperCase()}
        </h3>
      </div>
      <div style={{display: "grid", width: "100%"}}>
        <img className="overlay-img" src={dataStore.selectedImg.src}></img>
      </div>
    </div>
  )
});

export default PopUp;
