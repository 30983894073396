function Header({text}) {
  return (
      <div id="top" className='title-header'>
        <div className='text-line'><h1>{text}</h1></div>
        <div className='text-line'><h1>{text}</h1></div>
        <div className='text-line'><h1>{text}</h1></div>
        <div className='bottom-line'><h1>{text}</h1></div>
      </div>
  );
}

export default Header;
