import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../hooks/useStores";
import Skills from "./Skills";

const Gallery = observer(() => {
  const { dataStore } = useStores();

  const [currentSection, setCurrentSection] = useState("mixed");

  return (
    <div className="gallery-buttons" id="gallery-buttons">
      <div className="button-holder">
        {dataStore.loaded ? (
          Object.keys(dataStore.fullGallery).map((tag) => {
            return (
              <div
                className="filter-button"
                style={{
                  color: currentSection === tag && "#c1c1c1",
                  textDecoration: currentSection === tag && "underline",
                }}
                onClick={() => {
                  setCurrentSection(tag);
                }}
              >
                {tag.toUpperCase()}
              </div>
            );
          })
        ) : (
          <p style={{ color: "grey" }}>loading</p>
        )}
      </div>
      <div
        className="image-holder"
        style={{ maxWidth: "800px", margin: "auto" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            transitionDuration: "1s",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {dataStore.loaded &&
            Object.values(dataStore.fullGallery).map((section, i) => {
              return (
                <>
                  {section
                    .filter(
                      () =>
                        currentSection == Object.keys(dataStore.fullGallery)[i]
                    )
                    .map(({ title, farm, server, id, secret }) => {
                      return (
                        <div
                          className="image-container"
                          onClick={() => {
                            const newSelect = {
                              src: `http://farm${farm}.staticflickr.com/${server}/${id}_${secret}.jpg`,
                              title: `[${title}]`,
                              section: Object.entries(dataStore.fullGallery)[
                                i
                              ][0],
                            };
                            dataStore.updateSelectedImg(newSelect);
                          }}
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            position: "relative",
                            transitionDuration: "1s",
                            margin: "8px 4px",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={`http://farm${farm}.staticflickr.com/${server}/${id}_${secret}.jpg`}
                          />
                          <h3
                            style={{
                              position: "absolute",
                              width: "100%",
                              left: "0",
                              top: "50%",
                              textAlign: "center",
                              transform: "translateY(-50%)",
                              margin: 0,
                            }}
                          >
                            [{title}]
                          </h3>
                        </div>
                      );
                    })}
                </>
              );
            })}
        </div>
        {currentSection == "skills" && <Skills />}
      </div>
    </div>
  );
});

export default Gallery;
